<template>
  <div class="add_holiday">
    <div class="absent_app_form">
      <p>有休を付与します。</p>

      <select v-model="add_year">
        <!-- <option value="2019">2019</option>
        <option value="2020">2020</option>
        <option value="2021">2021</option> -->
        <option value="2022">2022</option>
        <option value="2023">2023</option>
        <option value="2024">2024</option>
        <option value="2025">2025</option>
        <option value="2026">2026</option>
        <option value="2027">2027</option>
        
        
        </select
      >年度分
      <button @click="handleClickAdd(add_year)">有休付与</button>
      <button @click="handleClickAdds(add_year)">連続有休付与</button>

      <div class="add_holi_close" @click="handleAddHolidayClose">
        <span class="material-symbols-outlined"> close </span>
      </div>
    </div>
  </div>
</template>

<script>
import { projectFirestore } from "@/firebase/config";
import { ref } from "vue";
import getUser from "../composables/getUser";
import getAdminDatas from "../composables/getAdminDatas";
import getCountHolidayforAdd from "../composables/getCountHolidayforAdd";

export default {
  props: [
    "user_id",
    "add_day",
    "date_of_add",
    "enter_date",
    "base_day",
    "use_holiday",
    "use_holiday_thisyear",
    "receive_total",
  ],
  data() {
    return {};
  },
  setup(props, context) {
    const { user } = getUser();
    const { admindatas, load } = getAdminDatas();
    const login_admin_name = ref("");

   

    const fetchData = async () => {
      await load();

      const login_admin = admindatas.value.filter((admin) => {
        console.log("admin_id", admin.admin_id);
        console.log("user", user.value.email);
        return admin.admin_id === user.value.email;
      });

      login_admin_name.value = login_admin[0].displayname;
    };
    fetchData();

    const add_year = ref(0);
    const base_date = new Date(props.base_day);
    const base_year = base_date.getFullYear();

    //便宜上はじめの有給基準日の年を入れておく
    add_year.value = base_year;

    //Note:今後自動付与、自動削除へ
    const handleClickAdds = (add_year) => {
      const date_of_add = new Date(props.date_of_add);

      const today = new Date();
      const this_year = today.getFullYear();
      const date_of_thisyear = new Date(
        this_year,
        date_of_add.getMonth(),
        date_of_add.getDate()
      );

      console.log("today", today);
      console.log("date_of_add", date_of_add);
      console.log("today< date_of_add", today < date_of_add);

      while (add_year < this_year) {
        clickAdd(add_year);
        add_year++;
      }
      if (add_year === this_year) {
        if (today < date_of_thisyear) {
          //有休付与日前なら何もしない
        } else {
          //有給付与日後なら、今年分の有給を追加
          handleClickAdd(add_year);
        }
        context.emit("holiday_add_finish");
      }
    };

    const handleClickAdd = async (add_year) => {
      const result_diffdays = await clickAdd(add_year);
      context.emit("holiday_add_finish",result_diffdays);
    };

    const clickAdd = async (add_year) => {
      const delete_date = ref("");
      const add_date = ref(new Date(props.date_of_add));
      const base_date = ref(
        new Date(add_year, add_date.value.getMonth(), add_date.value.getDate())
      );

      console.log("add_date_fullyear", add_date.value.getFullYear());
      console.log("add_year", add_year);

      //付与有休を計算

      const { num_of_add, diff_year } = getCountHolidayforAdd(
        props.enter_date,
        add_year
      );
      console.log("num_of_add", num_of_add);

      //const diff_days = calcDiffdays(diff_year);

      //付与日を指定
      const past_add_date = new Date(
        add_year,
        add_date.value.getMonth(),
        add_date.value.getDate()
      );
      console.log("past_add", past_add_date);
      //償還日の設定
      base_date.value.setFullYear(base_date.value.getFullYear() + 2);
      base_date.value.setDate(base_date.value.getDate() - 1);
      delete_date.value = new Date(base_date.value);

      const add_post = {
        user_id: props.user_id,
        //付与
        is_add: true,
        //日数
        num_of_date: num_of_add,
        //num_of_date: 1,
        //いつ付けでの付与か
        add_date: past_add_date,
        //期限
        delete_date: delete_date.value,
        //delete_date: new Date("2023-12-30"),
        //登録日
        createdAt: new Date(),
        //登録者
        created: login_admin_name.value,
      };

      try {
        const res = await projectFirestore
          .collection("holiday_add_delete")
          .add(add_post);

          return diff_days;
      } catch (err) {
        console.error("有給付与データ書き込みエラー", err.message);
      }
    };
    const handleAddHolidayClose = () => {
      context.emit("add_holiday_close");
    };

    const calcDiffdays = (diff_year) =>{
//前期までの精算
//３期目以降から実施
    if (diff_year >= 2) {
      //前期に使用した有給のトータル
      const use_holiday_lastyear =
        props.use_holiday - props.use_holiday_thisyear;

      //前々年の取得有給を確認
      let num_of_add = 0;

      const new_diff_year = diff_year - 2;

      if (new_diff_year <= 6) {
        switch (new_diff_year) {
          case 0:
            //6ヶ月目
            num_of_add = 10;
            break;
          case 1:
            //１年6ヶ月目
            num_of_add = 11;
            break;
          case 2:
            //2年6ヶ月目
            num_of_add = 12;
            break;
          case 3:
            //3年6ヶ月目
            num_of_add = 14;
            break;
          case 4:
            //4年6ヶ月目
            num_of_add = 16;
            break;
          case 5:
            //5年6ヶ月目
            num_of_add = 18;
            break;
          case 6:
            //6年6ヶ月目
            num_of_add = 20;
            break;
          default:
            num_of_add = 0;
            break;
        }
      } else {
        num_of_add = 20;
      }

      //前々年度の繰越利用分
      let diff_days = 0;
      if (num_of_add >= use_holiday_lastyear) {
        //前々年の取得有給を前年使用した有給トータルが越えなければ

        //前期利用分を適用
        diff_days = use_holiday_lastyear;
      } else {
        //前年に使用した有給トータルが前々年の取得有給を超えている

        //前期、前々期の２期分の差分を適用
        diff_days = props.receive_total - props.use_days;
      }
    }
    return diff_days;
  }
 

    return {
      add_year,
      handleClickAdd,
      handleAddHolidayClose,
      handleClickAdds,


    };
  },
};
</script>

<style>
.add_holiday .absent_app_form {
  position: relative;
}
.add_holi_close {
  position: absolute;
  right: 10px;
  top: 10px;
}
.add_holi_close .material-symbols-outlined {
  color: #2c3e50;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
}
</style>
