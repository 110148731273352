import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import { getStorage, uploadBytes, ref} from "firebase/storage";
import 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyCB7yIPZlci6xhjKrtPZ0unSOJmfzdMEwA",
  authDomain: "mik-engineers.firebaseapp.com",
  projectId: "mik-engineers",
  storageBucket: "mik-engineers.appspot.com",
  messagingSenderId: "1040472143812",
  appId: "1:1040472143812:web:8cf83c48ea694cae765691"

//テスト用環境
  // apiKey: "AIzaSyDDuB6sMpQlsslVTg6Mr4B9oy696F6-P18",
  //   authDomain: "mysystemtest-6f9d3.firebaseapp.com",
  //   projectId: "mysystemtest-6f9d3",
  //   storageBucket: "mysystemtest-6f9d3.appspot.com",
  //   messagingSenderId: "243930401539",
  //   appId: "1:243930401539:web:41207ab21234fb25caf736"
};

//init firebase
firebase.initializeApp(firebaseConfig);

const projectAuth = firebase.auth()
const projectFirestore = firebase.firestore();
const projectStorage = firebase.storage();
const now_timestamp = firebase.firestore.FieldValue.serverTimestamp
const settings = { timestampsInSnapshots: true };
projectFirestore.settings(settings);

const provider = new firebase.auth.OAuthProvider('microsoft.com')

export { projectAuth, projectFirestore, projectStorage,now_timestamp,provider};
