<template>
  <div class="aside">
    <img />
    <img src="@/assets/MIK.png" class="home_logo" />
    <h3>{{ screen_name }}</h3>

    <ul>
      <li>
        <router-link to="/home" class="aside_items" 
         

  
          @click="handleScreenReset('Home')"
        >
          <span class="material-symbols-outlined"> account_circle </span
          >エンジニアデータ</router-link
        >
      </li>
      <li>
        <router-link to="/skill" class="aside_items" @click="handleScreenReset('Skill')">
          <span class="material-symbols-outlined"> menu_book </span
          >エンジニアスキル・経歴</router-link
        >
      </li>
      <li>
        <router-link
          to="/rest"
          class="aside_items"
          @click="handleScreenReset('Rest')"
        >
          <span class="material-symbols-outlined">
            airline_seat_individual_suite </span
          >有給休暇データ</router-link
        >
      </li>
      <li>
        <router-link to="/point" class="aside_items" @click="handleScreenReset('Point')">
          <span class="material-symbols-outlined"> savings </span
          >社内貢献ポイント</router-link
        >
      </li>
      <li>
        <router-link to="/pay" class="aside_items" @click="handleScreenReset('Pay')">
          <span class="material-symbols-outlined"> payments </span
          >給与明細データ</router-link
        >
      </li>
      <!-- <li>
        <span class="material-symbols-outlined"> notifications </span>アプリ通知
      </li> -->
    </ul>

    <div class="aside_footer">
      <div class="logout" @click="handleLogout">
        <h3>LogOut</h3>
        <span class="material-symbols-outlined"> logout </span>
      </div>
    </div>
  </div>
</template>

<script>
import useLogout from './composables/useLogout';
import { useRouter } from 'vue-router';
export default {
  props:['screen_name','isDetail','user_id','page_no'],
  setup(props,context){
    console.log("props.user_id",props.user_id)
    const { logout, error } = useLogout();
    const router = useRouter();

    const handleLogout = async () => {
      await logout();
      if(!error.value){
        console.log("admin logged out")
        router.push({name: 'Admin_Welcome'})
      }
    }

    const handleScreenReset = (page_name) =>{

      console.log("props.isDetail",props.isDetail)
      const aside_selected_id = props.user_id;
      const aside_page_no = props.page_no;
      const is_detail = props.isDetail

      if(aside_selected_id && aside_page_no){

      if(props.isDetail){
        console.log("detail_screen_reset")
       
        router.push({name:page_name,state:{aside_id:aside_selected_id,aside_page_no:aside_page_no, aside_is_detail:is_detail}});
      }
      else{


        console.log("screen_reset")
    
      router.push({name:page_name});}
      }else{
        console.log("user_id,page_noが取得できませんでした。")
      }



      
    }

    return { handleLogout,handleScreenReset }
  },
  // methods:{
  //   handleScreenReset(){


  //   },
  // }


};
</script>

<style>
.aside {
  margin: 0;
  width: 228px;
  min-height: 100vh;
  padding: 32px 18px 0 18px;
  background: white;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.aside ul {
  height: 350px;
  padding: 0;
  margin-top: 86px;
  margin-left: 20px;
  font-size: 14px;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
}

.aside .material-symbols-outlined {
  color: #2c3e50;
  font-size: 20px;
  margin-right: 5px;
}
.aside li {
  display: flex;
  justify-content: center;
  align-items: center;
}

.aside_items {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}
.aside_footer {
  margin-top: auto;
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.aside_footer .material-symbols-outlined {
  color: #2c3e50;
  font-size: 24px;
  margin-right: 5px;
}

.logout {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.logout .material-symbols-outlined {
  margin-left: 5px;
}
</style>
